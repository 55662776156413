const groupsType = {
  GROUP_LIST: "GROUP_LIST_SUCCESS",
  GROUP_MEMBER_LIST: "GROUP_MEMBER_LIST_SUCCESS",
  GROUP_DETAILS: "GROUP_DETAILS_SUCCESS",
  FETCHING: "GROUP_LIST_FETCHING",
  ACTION_LOADER: "GROUP_LIST_ACTION_LOADER",
  PAGINATION: "GROUP_LIST_PAGINATION_SUCCESS",
};

export default groupsType;
