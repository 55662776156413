import React, { Fragment, Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };
  }

  render() {
    const {
      onCancel,
      open = false,
      showCancel = true,
      toggleModal,
      className,
      headingText = "",
      paragraphHeading = "",
      children,
      successAction,
      disabled = false,
      loading = false,
      successText = "",
      successColor = "primary",
      cancelColor = "danger",
    } = this.props;

    return (
      <Fragment>
        <Modal isOpen={open} toggle={toggleModal} className={className}>
          <ModalHeader toggle={this.toggleModal}>{headingText}</ModalHeader>
          <ModalBody>
            {paragraphHeading ? <h5>{paragraphHeading}</h5> : null}
            {children ? children : null}
          </ModalBody>
          <ModalFooter>
            {showCancel && (
              <Button
                color={cancelColor}
                outline
                onClick={onCancel || toggleModal}
              >
                Cancel
              </Button>
            )}
            <Button
              color={successColor}
              onClick={successAction}
              disabled={loading}
            >
              {loading && <Spinner className="mr-1" size="sm" color="white" />}
              {successText}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default CustomModal;
