const bookType = {
  BOOK_LIST: "BOOK_LIST_FETCHED_SUCCESS",
  BOOK_DETAILS: "BOOK_DETAILS_FETCHED_SUCCESS",
  FETCHING: "BOOK_LIST_FETCHING",
  VERIFY_LOADER: "VERIFY_LOADER",
  ACTION_LOADER: "BOOK_ACTION_LOADER",
  PAGINATION: "BOOK_LIST_PAGINATION_SUCCESS",
  CLEAN_UP: "BOOK_LIST_CLEAN_UP_SUCCESS",
};

export default bookType;
