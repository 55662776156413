const routes = {
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  recoverPassword: "/recover-password",
  dashboard: "/dashboard",
  users: "/users",
  categories: "/categories",
  interests: "/interests",
  subscriptionPlans: "/subscription-plans",
  groups: "/groups",
  books: "/books",
  monthlyPrizes: "/monthly-prizes",
  payments: "/payments",
  events: "/events",
  royaltyPayments: "/royalty-payments",
  usersBank: "/users-bank",
  reports: "/reports",
  raffle: "/contests",
  points: "/points",
  notifications: "/notifications",
  socialPosts: "/social-posts",
  activitiesLogs: "/activities-logs",
  settings: "/settings",
  subAdmins: "/subAdmin",
  staticContents: "/static-contents",
};

function getRoutes({ basename = "", routes }) {
  const newRoutes = {};

  Object.keys(routes).forEach((key) => {
    newRoutes[key] = basename + routes[key];
  });

  return newRoutes;
}

export default {
  ...getRoutes({ basename: `/pages`, routes }),
  root: "/",
};
