import subAdminType from "../../actionsType/subAdminType";

const initialState = {
  subAdminList: [],
  subAdminDetails: null,
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case subAdminType.SUB_ADMIN_LIST: {
      return { ...state, subAdminList: payload };
    }

    case subAdminType.SUB_ADMIN_DETAILS: {
      return { ...state, subAdminDetails: payload };
    }

    case subAdminType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case subAdminType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case subAdminType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
