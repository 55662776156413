import monthlyPrizesType from "../../actionsType/monthlyPrizesType";

const initialState = {
  prizeList: [],
  prizeDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case monthlyPrizesType.PRIZE_LIST: {
      return { ...state, prizeList: payload };
    }

    case monthlyPrizesType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case monthlyPrizesType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case monthlyPrizesType.PRIZE_DETAILS: {
      return { ...state, prizeDetails: payload };
    }

    case monthlyPrizesType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
