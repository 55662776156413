import { toast } from "react-toastify";
import routes from "../../../componentRoutes";
import { history } from "../../../history";
import authService from "../../../services/auth";
import storageService from "../../../services/storage";
import authType from "../../actionsType/authType";

const authAction = {
  login: (data, callback) => async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await authService.login(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.code === 200) {
      const {
        email,
        name,
        token,
        id: userId,
        role,
        permissions,
      } = response.data;

      storageService.set({
        value: { email, name, token, role, userId, permissions },
      });

      dispatch({ type: authType.CHANGE_ROLE, payload: role });
      dispatch({ type: authType.SET_USER, payload: { name, email, userId } });
      callback();
    } else {
      toast.error(response?.message);
    }
  },

  editProfile: (data, callback) => async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await authService.editProfile(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.code === 200) {
      const { name } = response.data[0];

      storageService.set({ value: { ...storageService.get(), name } });
      dispatch({ type: authType.SET_USER, payload: { name } });
      callback();
    } else {
      toast.error(response?.message);
    }
  },

  forgotPassword: (data, callback) => async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await authService.forgotPassword(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.code === 200) {
      toast.success(response?.message);
      callback();
    } else {
      toast.error(response?.message);
    }
  },

  recoverPassword: (data, callback) => async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await authService.resetPassword(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.code === 200) {
      toast.success(response?.message);
      callback();
    } else {
      toast.error(response?.message);
    }
  },

  changePassword: (data, callback) => async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await authService.changePassword(data);

    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.code === 200) {
      toast.success(
        "Password changes successfully. kindly login again to continue."
      );
      callback();
    } else {
      toast.error(response?.message);
    }
  },

  logout: () => {
    storageService.remove();
    history.replace(routes.login);
  },

  logoutWebApp: () => {
    storageService.clear();
    history.replace(routes.webAppLogin);
  },
};

export default authAction;
