import activitiesLogsType from "../../actionsType/activitiesLogsType";

const initialState = {
  activitiesLogsList: [],
  activityDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case activitiesLogsType.ACTIVITY_LOG_LIST: {
      return { ...state, activitiesLogsList: payload };
    }

    case activitiesLogsType.ACTIVITY_LOG_DETAILS: {
      return { ...state, activityDetails: payload };
    }

    case activitiesLogsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case activitiesLogsType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case activitiesLogsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
