import royaltyPaymentsType from "../../actionsType/royaltyPaymentsType";

const initialState = {
  royaltyPaymentList: [],
  royaltyPaymentDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case royaltyPaymentsType.ROYALTY_PAYMENT_LIST: {
      return { ...state, royaltyPaymentList: payload };
    }

    case royaltyPaymentsType.ROYALTY_PAYMENT_DETAILS: {
      return { ...state, royaltyPaymentDetails: payload };
    }

    case royaltyPaymentsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case royaltyPaymentsType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case royaltyPaymentsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
