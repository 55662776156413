import eventsType from "../../actionsType/eventsType";

const initialState = {
  eventsList: [],
  eventDetails: {},
  actionLoader: false,
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case eventsType.EVENTS_LIST: {
      return { ...state, eventsList: payload };
    }

    case eventsType.EVENT_DETAILS: {
      return { ...state, eventDetails: payload };
    }

    case eventsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case eventsType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case eventsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
