import usersType from "../../actionsType/usersType";

const initialState = {
  userList: [],
  userDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1, pageSize: 1, total: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case usersType.USER_LIST: {
      return { ...state, userList: payload };
    }

    case usersType.USER: {
      return { ...state, userDetails: payload };
    }

    case usersType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case usersType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case usersType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    case usersType.CLEAN_UP: {
      return { ...initialState };
    }

    default: {
      return { ...state };
    }
  }
};
