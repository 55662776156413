import booksType from "../../actionsType/booksType";

const initialState = {
  bookList: [],
  bookDetails: {},
  fetching: false,
  verifyLoader: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case booksType.BOOK_LIST: {
      return { ...state, bookList: payload };
    }

    case booksType.BOOK_DETAILS: {
      return { ...state, bookDetails: payload };
    }

    case booksType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case booksType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case booksType.VERIFY_LOADER: {
      return { ...state, verifyLoader: payload };
    }

    case booksType.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    case booksType.CLEAN_UP: {
      return { ...initialState };
    }

    default: {
      return { ...state };
    }
  }
};
