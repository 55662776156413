const usersType = {
  USER_LIST: "USER_LIST_SUCCESS",
  USER: "USER_SUCCESS",
  FETCHING: "USER_LIST_FETCHING",
  ACTION_LOADER: "USER_ACTION_LOADER",
  PAGINATION: "USER_LIST_PAGINATION_SUCCESS",
  CLEAN_UP: "USER_LIST_CLEAN_UP_SUCCESS",
};

export default usersType;
