import usersBankType from "../../actionsType/usersBankType";

const initialState = {
  usersBankList: [],
  userBankDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case usersBankType.USER_BANK_LIST: {
      return { ...state, usersBankList: payload };
    }

    case usersBankType.USER_BANK_DETAILS: {
      return { ...state, userBankDetails: payload };
    }

    case usersBankType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case usersBankType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case usersBankType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
