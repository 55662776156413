const pointsType = {
  USERS_POINTS_LIST: "USERS_POINTS_LIST_SUCCESS",
  USER_POINTS_DETAILS: "USER_POINTS_DETAILS_SUCCESS",
  FETCHING: "POINTS_FETCHING",
  ACTION_LOADER: "ACTION_LOADER",
  PAGINATION: "USERS_POINTS_LIST_PAGINATION_SUCCESS",
  USER_DETAILS_PAGINATION: "USER_POINTS_DETAILS_LIST_PAGINATION_SUCCESS",
};

export default pointsType;
