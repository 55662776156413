import reportsType from "../../actionsType/reportsType";

const initialState = {
  reportList: [],
  reportDetails: {},
  fetching: false,
  tempBlockLoader: false,
  permBlockLoader: false,
  unBlockLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case reportsType.REPORT_LIST: {
      return { ...state, reportList: payload };
    }

    case reportsType.REPORT_DETAILS: {
      return { ...state, reportDetails: payload };
    }

    case reportsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case reportsType.TEMP_BLOCK_LOADER: {
      return { ...state, tempBlockLoader: payload };
    }

    case reportsType.PERM_BLOCK_LOADER: {
      return { ...state, permBlockLoader: payload };
    }

    case reportsType.UNBLOCK_LOADER: {
      return { ...state, unBlockLoader: payload };
    }

    case reportsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
