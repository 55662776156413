import { combineReducers } from "redux";
import customizer from "./customizer/index";
import auth from "./auth/index";
import navbar from "./navbar/Index";
import users from "./users";
import books from "./books";
import categories from "./categories";
import interests from "./interests";
import subscriptionPlans from "./subscriptionPlans";
import monthlyPrizes from "./monthlyPrizes";
import groups from "./groups";
import points from "./points";
import payments from "./payments";
import royaltyPayments from "./royaltyPayments";
import usersBank from "./usersBank";
import reports from "./reports";
import raffle from "./raffle";
import events from "./events";
import socialPosts from "./socialPosts";
import notifications from "./notifications";
import staticContents from "./staticContents";
import subAdmin from "./subAdmin";
import activitiesLogs from "./activitiesLogs";

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  users,
  books,
  events,
  groups,
  raffle,
  points,
  categories,
  interests,
  payments,
  reports,
  subAdmin,
  socialPosts,
  activitiesLogs,
  usersBank,
  notifications,
  monthlyPrizes,
  royaltyPayments,
  subscriptionPlans,
  staticContents,
});

export default rootReducer;
