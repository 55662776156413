const reportsType = {
  REPORT_LIST: "REPORT_LIST_SUCCESS",
  REPORT_DETAILS: "REPORT_DETAILS_SUCCESS",
  UNBLOCK_LOADER: "REPORT_UNBLOCK_LOADER",
  TEMP_BLOCK_LOADER: "REPORT_TEMP_BLOCK_LOADER",
  PERM_BLOCK_LOADER: "REPORT_PERM_BLOCK_LOADER",
  FETCHING: "REPORT_LIST_FETCHING",
  PAGINATION: "REPORT_LIST_PAGINATION_SUCCESS",
};

export default reportsType;
