import storageService from "../../../services/storage";
import authType from "../../actionsType/authType";

const { name, role, email, userId } = storageService.get() || {};

const initialState = {
  userRole: role || "",
  name: name || "",
  email: email || "",
  userId: userId || "",
  fetching: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case authType.CHANGE_ROLE: {
      return { ...state, userRole: action.payload };
    }

    case authType.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case authType.SET_USER: {
      return { ...state, ...action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
