import httpService from "./http";

const authService = {
  login: (data) => httpService.post("/admin/login", data),
  editProfile: (data) => httpService.patch("/admin/update-profile", data),
  forgotPassword: (data) => httpService.post("/admin/forgot-password", data),
  changePassword: (data) => httpService.post("/admin/change-password", data),
  resetPassword: (data) => httpService.post("/admin/recover-password", data),
  verifyAddAdminToken: () => Promise.resolve(),
};

export default authService;
