import React from "react";
import * as Icon from "react-feather";
import accessControl from "../accessControl";
import routes from "../componentRoutes";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: routes.dashboard,
    show: accessControl.dashboard.showModule,
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.Users size={20} />,
    show: accessControl.users.showModule,
    navLink: routes.users,
  },
  {
    id: "book",
    title: "Books",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    show: accessControl.books.showModule,
    navLink: routes.books,
  },
  {
    id: "groups",
    title: "Groups",
    type: "item",
    icon: <Icon.Grid size={20} />,
    show: accessControl.groups.showModule,
    navLink: routes.groups,
  },
  {
    id: "manageCategory",
    title: "Categories",
    type: "item",
    icon: <Icon.Grid size={20} />,
    show: accessControl.categories.showModule,
    navLink: routes.categories,
  },
  {
    id: "interest",
    title: "Interests",
    type: "item",
    icon: <Icon.Server size={20} />,
    show: accessControl.interests.showModule,
    navLink: routes.interests,
  },
  {
    id: "subscriptionPlans",
    title: "Subscription plans",
    type: "item",
    icon: <Icon.Rss size={20} />,
    show: accessControl.subscriptionPlans.showModule,
    navLink: routes.subscriptionPlans,
  },
  {
    id: "monthlyPrize",
    title: "VIP Trivia",
    type: "item",
    icon: <Icon.Gift size={20} />,
    show: accessControl.monthlyPrizes.showModule,
    navLink: routes.monthlyPrizes,
  },
  {
    id: "payments",
    title: "Payments",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    show: accessControl.payments.showModule,
    navLink: routes.payments,
  },
  // {
  //   id: "usersBank",
  //   title: "Users Bank",
  //   type: "item",
  //   icon: <Icon.DollarSign size={20} />,
  //   permissions: ["admin"],
  //   navLink: routes.usersBank,
  // },
  {
    id: "royaltyPayment",
    title: "Royalty Payments",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    show: accessControl.royaltyPayments.showModule,
    navLink: routes.royaltyPayments,
  },
  {
    id: "event",
    title: "Events",
    type: "item",
    icon: <Icon.Rss size={20} />,
    show: accessControl.events.showModule,
    navLink: routes.events,
  },
  {
    id: "report-abuse",
    title: "Report Abuse",
    type: "item",
    icon: <Icon.Flag size={20} />,
    show: accessControl.reports.showModule,
    navLink: routes.reports,
  },
  {
    id: "raffle",
    title: "Contests",
    type: "item",
    icon: <Icon.Gift size={20} />,
    show: accessControl.raffle.showModule,
    navLink: routes.raffle,
  },
  {
    id: "points",
    title: "Points",
    type: "item",
    icon: <Icon.Award size={20} />,
    show: accessControl.points.showModule,
    navLink: routes.points,
  },
  {
    id: "notifications",
    title: "Notifications",
    type: "item",
    icon: <Icon.Bell size={20} />,
    show: accessControl.notifications.showModule,
    navLink: routes.notifications,
  },
  {
    id: "social-posts",
    title: "Social Posts",
    type: "item",
    icon: <Icon.Grid size={20} />,
    show: accessControl.socialPosts.showModule,
    navLink: routes.socialPosts,
  },
  {
    id: "activities-log",
    title: "Activities Log",
    type: "item",
    icon: <Icon.Grid size={20} />,
    show: accessControl.activitiesLogs.showModule,
    navLink: routes.activitiesLogs,
  },
  {
    id: "sub-admins",
    title: "Sub-Admins",
    type: "item",
    icon: <Icon.Grid size={20} />,
    show: accessControl.subAdmins.showModule,
    navLink: routes.subAdmins,
  },
  {
    id: "staticContents",
    title: "Static Contents",
    type: "item",
    icon: <Icon.File size={20} />,
    show: accessControl.staticContents.showModule,
    navLink: routes.staticContents,
  },
];

export default navigationConfig;
