import paymentsType from "../../actionsType/paymentsType";

const initialState = {
  paymentList: [],
  totalPayment: 0,
  paymentDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case paymentsType.PAYMENT_LIST: {
      return { ...state, paymentList: payload };
    }

    case paymentsType.TOTAL_PAYMENT: {
      return { ...state, totalPayment: payload };
    }

    case paymentsType.PAYMENT_DETAILS: {
      return { ...state, paymentDetails: payload };
    }

    case paymentsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case paymentsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
