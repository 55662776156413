import staticContentsType from "../../actionsType/staticContentsType";

const initialState = {
  staticContents: null,
  actionLoader: false,
  fetching: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case staticContentsType.STATIC_CONTENTS: {
      return { ...state, staticContents: payload };
    }

    case staticContentsType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case staticContentsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    default: {
      return { ...state };
    }
  }
};
