import notificationsType from "../../actionsType/notificationsType";

const initialState = {
  notificationList: [],
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case notificationsType.NOTIFICATION_LIST: {
      return { ...state, notificationList: payload };
    }

    case notificationsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case notificationsType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case notificationsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
