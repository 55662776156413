import groupsType from "../../actionsType/groupsType";

const groupMemberList = { userList: [], pagination: { pageNo: 1, pages: 1 } };

const initialState = {
  groupList: [],
  groupMemberList: { ...groupMemberList },
  groupDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case groupsType.GROUP_LIST: {
      return { ...state, groupList: payload };
    }

    case groupsType.GROUP_MEMBER_LIST: {
      return { ...state, groupMemberList: payload };
    }

    case groupsType.GROUP_DETAILS: {
      return { ...state, groupDetails: payload };
    }

    case groupsType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case groupsType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case groupsType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
