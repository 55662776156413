import raffleType from "../../actionsType/raffleType";

const initialState = {
  rafflesList: [],
  raffleTicketsList: [],
  raffleDetails: null,
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
  ticketsPagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case raffleType.RAFFLES_LIST: {
      return { ...state, rafflesList: payload };
    }

    case raffleType.RAFFLE_TICKETS_LIST: {
      return { ...state, raffleTicketsList: payload };
    }

    case raffleType.RAFFLE_DETAILS: {
      return { ...state, raffleDetails: payload };
    }

    case raffleType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case raffleType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case raffleType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    case raffleType.TICKETS_PAGINATION: {
      return { ...state, ticketsPagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
