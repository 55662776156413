import storageService from "../services/storage";

const getAccess = (userRoles = [], customModule = []) => {
  const { permissions, role } = storageService.get() || {};
  if (role !== "custom") return userRoles.includes(role);
  console.log(
    customModule.map((each) => permissions.includes(each)).filter((_) => _)
      .length > 0
  );

  return (
    customModule.map((each) => permissions.includes(each)).filter((_) => _)
      .length > 0
  );
};

const accessControl = {
  dashboard: {
    showModule: getAccess(["super_admin", "admin", "editor"], ["dashboard"]),
  },
  users: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["users_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["user_details"]
    ),
    showAddToPaperClipUser: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_to_paperclip_user"]
    ),
    showBlockUnblockUser: getAccess(
      ["super_admin", "admin"],
      ["block_unblock_user"]
    ),
  },
  books: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["books_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["book_details"]
    ),
    showVerifyReject: getAccess(
      ["super_admin", "admin", "editor"],
      ["verify_reject_book"]
    ),
    showChangeBookContent: getAccess(
      ["super_admin", "admin", "editor"],
      ["change_book_content"]
    ),
    showPublishBook: getAccess(
      ["super_admin", "admin", "editor"],
      ["publish_book"]
    ),
    showChangePublisher: getAccess(
      ["super_admin", "admin", "editor"],
      ["change_book_publisher"]
    ),
    showFeatureBook: getAccess(
      ["super_admin", "admin", "editor"],
      ["feature_book"]
    ),
    showAddToPaperClip: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_to_paperclip_book"]
    ),
    showContinueDiscontinue: getAccess(
      ["super_admin", "admin"],
      ["continue_discontinue_book"]
    ),
    showDeleteBook: getAccess(["super_admin", "admin"], ["delete_book"]),
  },
  groups: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["groups_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["group_details"]
    ),
    showAddEditGroup: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_edit_group"]
    ),
    showGroupMember: getAccess(
      ["super_admin", "admin", "editor"],
      ["group_member"]
    ),
    showEnableDisableGroup: getAccess(
      ["super_admin", "admin"],
      ["enable_disable_group"]
    ),
  },
  categories: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["categories_listing"]
    ),
    showAddEditCategory: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_edit_category"]
    ),
    showDeleteCategory: getAccess(
      ["super_admin", "admin"],
      ["delete_category"]
    ),
  },
  interests: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["interests_listing"]
    ),
    showAddEditInterest: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_edit_interest"]
    ),
    showDeleteInterest: getAccess(
      ["super_admin", "admin"],
      ["delete_interest"]
    ),
  },
  subscriptionPlans: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["subscription_plans_listing"]
    ),
    showSubscribedUsers: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["subscribed_users"]
    ),
  },
  monthlyPrizes: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["prizes_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["prize_details"]
    ),
    showAddEditPrize: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_edit_prize"]
    ),
    showContinueDiscontinuePrize: getAccess(
      ["super_admin", "admin", "editor"],
      ["continue_discontinue_prize"]
    ),
    showDeletePrize: getAccess(["super_admin", "admin"], ["delete_prize"]),
  },
  payments: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["payments_listing"]
    ),
    showDetails: getAccess(["super_admin", "admin"], ["payment_details"]),
  },
  royaltyPayments: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["royalty_payments_listing"]
    ),
  },
  events: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["events_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["event_details"]
    ),
    showEnableDisableEvent: getAccess(
      ["super_admin", "admin"],
      ["enable_disable_event"]
    ),
  },
  reports: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["reports_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["report_details"]
    ),
    showBlockUser: getAccess(["super_admin", "admin"], ["block_unblock_user"]),
  },
  raffle: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["raffle_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["raffle_details"]
    ),
    showTicketList: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["tickets_listing"]
    ),
    showAddEditRaffle: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_edit_raffle"]
    ),
  },
  points: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["points_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["points_details"]
    ),
    showCreditDebitPoints: getAccess(
      ["super_admin", "admin"],
      ["credit_debit_points"]
    ),
  },
  notifications: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["notifications_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["notification_details"]
    ),
    showAddNotification: getAccess(
      ["super_admin", "admin"],
      ["add_notification"]
    ),
    showResendNotification: getAccess(
      ["super_admin", "admin"],
      ["resend_notification"]
    ),
  },
  socialPosts: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["social_posts_listing"]
    ),
    showDetails: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["post_details"]
    ),
    showBlockUnblockPost: getAccess(
      ["super_admin", "admin"],
      ["block_unblock_post"]
    ),
  },
  settings: {
    showModule: true,
  },
  activitiesLogs: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["activities_logs_listing"]
    ),
  },
  subAdmins: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["sub_admins_listing"]
    ),
    showDetails: getAccess(["super_admin", "admin", ["sub_admin_details"]]),
    showAddEditSubAdmin: getAccess(
      ["super_admin", "admin"],
      ["add_edit_sub_admin"]
    ),
    showEnableSubAdmin: getAccess(
      ["super_admin", "admin"],
      ["enable_sub_admin"]
    ),
  },
  staticContents: {
    showModule: getAccess(
      ["super_admin", "admin", "editor", "team_member"],
      ["static_contents"]
    ),
    showAddEditContent: getAccess(
      ["super_admin", "admin", "editor"],
      ["add_edit_static_contents"]
    ),
  },
};

export default accessControl;
