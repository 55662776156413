const raffleType = {
  RAFFLES_LIST: "RAFFLES_LIST_SUCCESS",
  RAFFLE_TICKETS_LIST: "RAFFLE_TICKETS_LIST_SUCCESS",
  RAFFLE_DETAILS: "RAFFLE_DETAILS_SUCCESS",
  FETCHING: "RAFFLE_FETCHING",
  ACTION_LOADER: "RAFFLE_ACTION_LOADER",
  PAGINATION: "RAFFLES_LIST_PAGINATION_SUCCESS",
  TICKETS_PAGINATION: "RAFFLE_TICKETS_LIST_PAGINATION_SUCCESS",
};

export default raffleType;
