import interestsType from "../../actionsType/interestsType";

const initialState = {
  interestList: [],
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case interestsType.INTEREST_LIST: {
      return { ...state, interestList: action.payload };
    }

    case interestsType.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case interestsType.ACTION_LOADER: {
      return { ...state, actionLoader: action.payload };
    }

    case interestsType.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    case interestsType.CLEAN_UP: {
      return { ...initialState };
    }

    default: {
      return { ...state };
    }
  }
};
