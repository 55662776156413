import subscriptionPlansType from "../../actionsType/subscriptionPlansType";

const subUserList = { userList: [], pagination: { pageNo: 1, pages: 1 } };

const initialState = {
  planList: [],
  subUserList: { ...subUserList },
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  const { type, payload } = action;

  switch (type) {
    case subscriptionPlansType.SUBSCRIPTION_LIST: {
      return { ...state, planList: payload };
    }

    case subscriptionPlansType.FETCHING: {
      return { ...state, fetching: payload };
    }

    case subscriptionPlansType.SUB_USER_LIST: {
      return { ...state, subUserList: payload };
    }

    case subscriptionPlansType.ACTION_LOADER: {
      return { ...state, actionLoader: payload };
    }

    case subscriptionPlansType.PAGINATION: {
      return { ...state, pagination: payload };
    }

    default: {
      return { ...state };
    }
  }
};
